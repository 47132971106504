/* ------------------------------------*\
    #TEASER-CONSULTANT-POINTS
\*------------------------------------ */
$teaser-consultant-points-item-size: 26px;
$teaser-consultant-points-item-border-after: 0 solid transparent;
$teaser-consultant-points-item-border-before: 2px solid transparent;
$teaser-consultant-points-circle-size: 70%;

.c-teaser-consultant-points {
	position: relative;
}

.c-teaser-consultant-points__item {
	width: $teaser-consultant-points-item-size;
	height: $teaser-consultant-points-item-size;
	display: block;
	cursor: pointer;
	background: none;
	border: 0;
	box-sizing: border-box;
	position: absolute;
	vertical-align: middle;
	padding: 0;
	border-radius: 100%;

	&::after {
		box-sizing: inherit;
		border-radius: 100%;
		border: $teaser-consultant-points-item-border-after;
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&::before {
		box-sizing: inherit;
		border-radius: 100%;
		border: $teaser-consultant-points-item-border-before;
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	/* white circle animation */
	&:hover {
		&::before {
			border-top-color: $color-white;
			border-right-color: $color-white;
			border-bottom-color: $color-white;
			transition:
				border-top-color 0.15s linear,
				border-right-color 0.15s linear 0.1s,
				border-bottom-color 0.15s linear 0.2s;
		}

		&::after {
			border-top: 2px solid $color-white;
			border-left-width: 2px;
			border-right-width: 2px;
			-webkit-transform: rotate(270deg);
			transform: rotate(270deg);
			transition: border-left-width 0s linear 0.35s, -webkit-transform 0.4s linear 0s;
			transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s;
			transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s, -webkit-transform 0.4s linear 0s;
		}
	}

	&.c-teaser-consultant-points__item--bobble1 {
		top: 25%;
		right: 63%;
	}

	&.c-teaser-consultant-points__item--bobble2 {
		top: 40%;
		right: 78%;
	}

	&.c-teaser-consultant-points__item--bobble3 {
		top: 27%;
		right: 22%;
	}

	&.c-teaser-consultant-points__item--bobble4 {
		top: 50%;
		right: 38%;
	}

	&.c-teaser-consultant-points__item--bobble5 {
		top: 66%;
		right: 56%;
	}

	&.c-teaser-consultant-points__item--bobble6 {
		top: 83%;
		right: 19%;
	}
}

.c-teaser-consultant-points__circle {
	width: $teaser-consultant-points-circle-size;
	height: $teaser-consultant-points-circle-size;
	position: absolute;
	top: 15%;
	left: 15%;
	border-radius: 100%;
	background: $color-white;
}
