/* ------------------------------------*\
    #WELCOME LAYER
\*------------------------------------ */

$welcome-layer-width: 660px;
$welcome-layer-padding: 20px;

.c-welcome-layer {
	max-width: $welcome-layer-width;
	padding: $welcome-layer-padding;
	background-color: $color-white;

	@include media-query(m-down) {
		max-width: 100%;
		width: 100%;
	}
}

.c-welcome-layer__headline {
	font-weight: $font-weight-bold;

	@include font-size($inuit-heading-size-3, 1.2);

	margin-bottom: $spacing-unit--small;

	> span {
		display: block;
		font-size: $heading-secondary-font-size-delta;
		font-weight: $font-weight-normal;
	}
}
