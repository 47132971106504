/* ------------------------------------*\
    #PRODUCT-DETAIL-TILE
\*------------------------------------ */

.c-inspiration-home__hero {
	@media screen and (min-width: 768px) {
		grid-column: 2 / span 2;

		& + * {
			grid-column-start: 1;
			grid-row-start: 1;
		}
	}

	> .c-inspiration-home__img {
		@include media-query(m-up) {
			@include aspect-ratio(900, 430);
		}

		@include media-query(m-down) {
			@include aspect-ratio(440, 432);
		}
	}
}

.inspiration-440x288 {
	.c-inspiration-home__img {
		@include aspect-ratio(440, 288);
	}
}

.inspiration-440x574 {
	.c-inspiration-home__img {
		@include aspect-ratio(440, 574);
	}
}

.inspiration-440x432,
.inspiration-hero-440x432 {
	.c-inspiration-home__img {
		@include aspect-ratio(440, 432);
	}
}

.js-inspiration-home-copy.isCropped::after {
	content: "";
	position: absolute;
	bottom: 0;
	right: 0;
	width: 50%;
	height: $inuit-base-line-height;
	background: linear-gradient(to right, transparent, var(--color-white) 80%);
}
