/* ------------------------------------*\
    #COUPON
\*------------------------------------ */

$coupon-input-height: 42px;

.c-coupon {
	padding: ($spacing-unit--small + $spacing-unit--tiny);
	background-color: $color-gray-lighter;
}

.c-coupon__content {
	@include media-query(m-down) {
		overflow-y: hidden;
		max-height: 0;

		@include ui-transition(0.8s, ease-out);
	}
}

.c-coupon__title {
	@include font-size($inuit-heading-size-5, 1.5);

	margin-bottom: $spacing-unit--tiny;

	@include media-query(m-down) {
		margin-bottom: 0;
	}

	&.is-open + .c-coupon__content {
		@include media-query(m-down) {
			max-height: 500px;

			@include ui-transition(1s, ease-in);
		}
	}

	.c-icon {
		@include ui-transition();

		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	&.is-open .c-icon {
		@include media-query(m-down) {
			@include ui-transition();

			-webkit-transform: rotate(180deg);
			-o-transform: rotate(180deg);
			transform: rotate(180deg);
		}
	}
}

.c-coupon__input {
	width: 100%;
	height: $coupon-input-height;
	line-height: $coupon-input-height;
	border: none;

	@include media-query(m-down) {
		margin-top: $spacing-unit--tiny;
	}
}
