/* ------------------------------------*\
    #ORDER-HISTORY
\*------------------------------------ */

// todo: Price Summary angleichen: Cart, Checkout-Summary, Order-History, Return Kacheln, Return Summary, etc.

.c-price-summary-totalprice {
	font-size: $inuit-heading-size-4;

	> span {
		font-weight: $font-weight-bold;
	}

	@include media-query(m-down) {
		font-weight: $font-weight-bold;
		font-size: $inuit-heading-size-6;
	}
}
