/* ------------------------------------*\
    #errorCVV
\*------------------------------------ */
$errorVV-height: 14px;
$errorCVV-margin-top: 9px;

.errorCVV {
	height: $errorVV-height;
	display: block;
	margin-top: $errorCVV-margin-top;
}
