/* ------------------------------------*\
    #TEASER-CONSULTANT
\*------------------------------------ */
$teaser-consultant-headline-mb: $spacing-unit;
$teaser-consultant-headline-border-pos-b: -8px;
$teaser-consultant-headline-border-width: 70%;
$teaser-consultant-headline-border-width-m: 60%;
$teaser-consultant-content-width: 48%;
$teaser-consultant-content-width-m: 55%;
$teaser-consultant-content-height: 80%;
$teaser-consultant-content-height-m-down: 68%;
$teaser-consultant-content-padding: 0 10%;
$teaser-consultant-bottom-content-background: rgba(0, 0, 0, 0.5);
$teaser-consultant-area-width: 85%;
$teaser-consultant-area-background-hover: rgba(255, 255, 255, 1);
$teaser-consultant-area-item-text-padding: 10px;
$teaser-consultant-area-item-text-padding-m: 7px;

.c-teaser-consultant {
	@include media-query(m-up) {
		@include aspect-ratio(1364, 334);
	}

	@include media-query(m-down) {
		@include aspect-ratio(360, 150);
	}
}

.c-teaser-consultant__content {
	float: left;
	position: relative;
	display: flex;
	width: $teaser-consultant-content-width;
	height: $teaser-consultant-content-height;
	padding: $teaser-consultant-content-padding;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	color: $color-white;

	@include media-query(m-down) {
		width: 100%;
		height: $teaser-consultant-content-height-m-down;
	}

	@include media-query(m) {
		width: $teaser-consultant-content-width-m;
	}
}

.c-teaser-consultant__subline {
	display: block;
	font-weight: $font-weight-medium;
	padding-left: $inuit-base-line-height/2;

	@include font-size($heading-secondary-font-size-delta, 1.2);

	text-align: center;
	color: $color-white;

	@include media-query(l) {
		font-size: $heading-secondary-font-size-epsilon;
	}

	@include media-query(m) {
		font-size: $secondary-font-size-s;
		padding-left: 0;
	}
}

.c-teaser-consultant__headline {
	display: inline-block;
	position: relative;
	margin-bottom: $teaser-consultant-headline-mb;
	font-weight: $font-weight-medium;
	color: $color-white;

	@include font-size($heading-secondary-font-size-alpha);

	@include media-query(xl-down) {
		@include font-size($heading-secondary-font-size-beta);
	}

	&::after {
		content: "";
		position: absolute;
		left: 50%;
		height: 1px;
		bottom: $teaser-consultant-headline-border-pos-b;
		border-bottom: 1px solid $color-white;
		margin-left: -($teaser-consultant-headline-border-width/2);
		width: $teaser-consultant-headline-border-width;

		@include media-query(m-down) {
			margin-left: -($teaser-consultant-headline-border-width-m/2);
			width: $teaser-consultant-headline-border-width-m;
		}
	}
}

.c-teaser-consultant__img {
	position: absolute;
	width: 100%;
	height: auto;
	left: 0;
}

.c-teaser-consultant__bottom {
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: $spacing-unit 0;
	z-index: 1;
	background: $teaser-consultant-bottom-content-background;

	@include media-query(l-down) {
		padding: $spacing-unit--small;
	}
}

.c-teaser-consultant__bottom-area {
	width: $teaser-consultant-area-width;
	margin: 0 auto;
}

.c-teaser-consultant__bottom-area-item {
	display: block;
	position: relative;
	color: $color-white;

	&:hover {
		background-color: $teaser-consultant-area-background-hover;
	}
}

.c-teaser-consultant__bottom-area-item-text {
	padding-top: $teaser-consultant-area-item-text-padding;
	padding-bottom: $teaser-consultant-area-item-text-padding;
	text-align: center;
	font-size: $heading-secondary-font-size-delta;
	line-height: 1;
	font-weight: $font-weight-bold;
	border: 1px solid $color-white;

	&:hover {
		color: $color-black;
	}

	@include media-query(m) {
		font-size: $secondary-font-size-s;
		padding-top: $teaser-consultant-area-item-text-padding-m;
		padding-bottom: $teaser-consultant-area-item-text-padding-m;
	}
}
