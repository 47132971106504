/* ------------------------------------*\
    #PARCELLAB-POPUP
\*------------------------------------ */

$parcellab-content-min-height: 100px;
$parcellab-content-min-height-m-down: 125px;

.c-parcellab-popup {
	@include media-query(m-down) {
		position: relative;
	}
}

.c-parcellab-popup__headline {
	@include media-query(m-down) {
		font-size: $inuit-heading-size-4;
	}
}

.c-parcellab-popup__wrapper {
	min-height: 52px;
	position: relative;
	z-index: 3;
	#pl-courier-fwd-link,
	#pl-reroute-link {
		border-radius: 9999px;
	}
}

.c-parcellab-popup__content {
	position: relative;
	min-height: $parcellab-content-min-height;

	&--small {
		min-height: $parcellab-content-min-height / 2;
	}

	@include media-query(m-down) {
		min-height: $parcellab-content-min-height-m-down;
	}
}
