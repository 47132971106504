/* ------------------------------------*\
    #PRIMARY NAV
\*------------------------------------ */
@import "../../shared/settings/header-settings";

//-----------------------------------
// Primary-Navigation / Department
//-----------------------------------

.c-primary-nav-container {
	@extend .ocm-body1;

	align-items: center;
	display: inline-flex;
	height: 100%;
	margin-right: 1rem;
	position: relative;

	&::after {
		content: "";
		width: 2px;
		height: 25%;
		background: var(--color-gray-70);
		position: absolute;
		right: -2px;
		top: 0;
		transform: translateY(150%);
	}

	.is-header-sticky & {
		.c-primary-nav {
			&.is-open {
				display: block;
			}
		}

		@media (min-width: 64em) {
			&:hover > .c-primary-nav {
				.no-touchevents & {
					display: block;
				}
			}
		}
	}
}

.c-primary-nav__item {
	width: 100%;

	@media (min-width: 88em) {
		margin-right: 0.857142857142857rem;
		width: auto;
	}
}

.c-primary-nav__link {
	color: var(--color-black-primary);
	font-weight: 500;
	display: inline-block;

	span {
		display: inline-block;
		padding: 0.285714285714286rem 0;
		border-bottom: 2px solid transparent;
		border-top: 2px solid transparent;
	}

	.no-touchevents & {
		@include animate_underline_on_hover($line-height: 2px, $duration: 240ms, $element: "span");
	}

	&.is-active span {
		border-bottom-color: var(--color-black-primary);
	}

	@media not all and (min-width: 88em) {
		outline-offset: -2px;
		align-items: center;
		background-color: white;
		border-bottom: 0;
		display: flex;
		height: 3.428571428571429rem;
		padding: 0 1.142857142857143rem;
		width: 100%;

		&.is-active {
			color: black !important;
		}

		&:hover {
			background-color: var(--color-gray-40);
			color: black !important;
		}
	}
}

.c-primary-nav-toggle {
	@extend .ocm-body1;

	align-items: center;
	display: flex;
	font-weight: 500;
	list-style-type: none;
	margin: 0;
	padding: 0;

	@media not all and (min-width: 88em) {
		align-items: flex-start;
		border-top: 2px solid var(--color-gray-40);
		box-shadow: var(--shadow-4);
		clip-path: inset(0 -10px -10px -10px);
		display: none;
		flex-direction: column;
		left: -1.142857142857143rem;
		position: absolute;
		transform: translateY(calc(4rem));
		top: 0;
		width: 10rem;
		&.is-open {
			display: flex;
		}
	}
}

.c-primary-nav-toggle-button {
	-webkit-appearance: button;
	background-color: transparent;
	background-image: none;
	border: none;
	display: flex;
	@media (min-width: 88em) {
		display: none;
	}
}
