/* ------------------------------------*\
 #  errorLayerpage.jsp
\*------------------------------------ */

$login-layer-width: 430px;
$login-layer-padding: 20px;
$login-layer-max-width: 500px;

.c-login-layer {
	padding: $spacing-unit $login-layer-padding;
	background-color: $color-white;
	max-width: $login-layer-max-width + (2 * $login-layer-padding);
	margin: 0 auto;

	.popupContent & {
		width: 100%;

		@include media-query(m-up) {
			max-width: $login-layer-width;
		}
	}
}
