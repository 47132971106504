/* ------------------------------------*\
    #POPUP-ACCOUNT
\*------------------------------------ */
$popup-account-max-width: 600px;

.c-popup-account {
	padding: $spacing-unit;
	background-color: $color-white;

	@include media-query(m-down) {
		width: 100%;
		padding: $spacing-unit;
		height: 100%;
	}
}
