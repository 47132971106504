/* ------------------------------------*\
    #WAITING OVERLAY
\*------------------------------------ */

$waiting-popup-bg: url(/_ui/responsive/common/images/loader/loading.gif) no-repeat center center;

.c-waiting-container {
	width: 500px;
	height: 300px;
	max-width: 100%;
	max-height: 100%;
	background: $waiting-popup-bg;
	background-size: 60px 60px;
}
