/* ------------------------------------*\
    #BADGE
\*------------------------------------ */

$badge-spacing: $inuit-base-spacing-unit--small;

/**
 * Badge with extra information.
 * Stick it on anything you want.
 * THIS OBJECT NEEDS AN CONTAINER WITH RELATIVE POSITIONING.
 *
 * Extend `.o-badge {}` in components/_badge.scss.
 */

.o-badge {
	position: absolute;
	z-index: z("badge");
}

.o-badge--left {
	left: $badge-spacing;

	&.o-badge--flush {
		left: 0;
	}
}

.o-badge--right {
	top: 4px;
	right: 4px;
}
