/* ------------------------------------*\
    #POPUP-ADDRESS-CHECK
\*------------------------------------ */

$popup-check-address-width: 700px;

.c-popup-check-address {
	padding: $spacing-unit;
	max-width: $popup-check-address-width;
	background-color: $color-white;
	overflow: auto;

	.c-popup-check-address--close {
		position: absolute;
		top: -$inuit-base-spacing-unit;
		right: -$inuit-base-spacing-unit;
		padding: $inuit-base-spacing-unit--small;
		font-size: $inuit-base-font-size;
		border-radius: 50%;
		color: $color-white;
		background-color: $color-black;
		border: none;

		@include media-query(m-down) {
			top: $inuit-base-spacing-unit--small;
			right: $inuit-base-spacing-unit--small;
		}
	}

	@include media-query(m-down) {
		width: 100%;
		padding: $spacing-unit;
		overflow: hidden;
	}
}
