/* ------------------------------------*\
    #TEASER-CONSULTANT-VIDEO
\*------------------------------------ */
$teaser-consultant-video-text-width: 70%;

.c-teaser-consultant-video {
	@include media-query(m-up) {
		background-color: $color-black;
	}
}

.c-teaser-consultant-video__item {
	position: relative;
	display: block;
}

.c-teaser-consultant-video__image {
	width: 100%;

	@include aspect-ratio(320, 160);
}

.c-teaser-consultant-video__overlay {
	color: $color-white;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.c-teaser-consultant-video__headline {
	text-align: center;
	font-size: $heading-secondary-font-size-gamma;

	@include media-query(l-down) {
		font-size: $heading-secondary-font-size-delta;
		margin-bottom: $inuit-base-line-height/2;
	}
}

.c-teaser-consultant-video__text {
	@include media-query(l-up) {
		padding: 0 $spacing-unit--huge;
	}

	@include media-query(m) {
		padding: 0 $spacing-unit;
	}

	@include media-query(m-up) {
		color: $color-white;
	}
}

.c-teaser-consultant-video__text-headline {
	font-weight: $font-weight-medium;
	font-size: $heading-secondary-font-size-delta;

	@include media-query(m-down) {
		margin-top: $spacing-unit;
		font-size: $heading-secondary-font-size-gamma;
	}
}
