/* ------------------------------------*\
    #COUNT LIST
\*------------------------------------ */
$count-list-height: 30px;
$count-list-height-m: 32px;
$count-list-width: 50px;
$count-list-width-m: 62px;

.c-count-list {
	position: relative;

	> a {
		display: block;
		height: $count-list-height;
		min-width: $count-list-width;
		line-height: $count-list-height;
		border: 1px solid $color-black;

		> span {
			padding-left: $spacing-unit--small;
		}

		&::after {
			position: absolute;
			top: 0;
			right: 0;

			@include icon-font();

			line-height: $count-list-height;
			font-weight: $font-weight-bold;
			content: $icon-font--arrow-down;
			font-size: $inuit-heading-size-micro;
			padding-right: $spacing-unit--small;

			@include media-query(m-down) {
				line-height: $count-list-height-m;
			}
		}

		@include media-query(m-down) {
			height: $count-list-height-m;
			line-height: $count-list-height-m;
			min-width: $count-list-width-m;
		}
	}

	ul {
		position: absolute;
		width: 100%;
		border: 1px solid $color-black;
		border-top-width: 0;
		background-color: $color-white;
		z-index: 1;

		> li {
			width: 100%;
			padding-left: $spacing-unit--small;
			cursor: pointer;

			&:hover {
				background-color: $color-gray-lighter;
			}

			@include media-query(m-down) {
				height: $count-list-height-m;
				line-height: $count-list-height-m;
			}
		}
	}
}
