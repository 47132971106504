/* ------------------------------------*\
    #BOILERPLATE / Abbinder auf der Home
\*------------------------------------ */

$boilerplate-item-mb: $inuit-base-spacing-unit;
$boilerplate-quote-font-size: 30px;
$boilerplate-quote-line-height: 1.2;
$boilerplate-quote-mb: 10px;

.c-boilerplate__item {
	margin-bottom: $boilerplate-item-mb;

	p:last-child {
		padding-bottom: 0;
		margin-bottom: 0;
	}
}

.c-boilerplate__item--border-left {
	border-left: 1px solid $color-black;
	position: relative;
}

.c-boilerplate__quote {
	@include font-size($boilerplate-quote-font-size, $boilerplate-quote-line-height);

	margin-bottom: $boilerplate-quote-mb;
}

.c-boilerplate__header {
	font-size: $u-fontsize;
	font-weight: $font-weight-bold;
	margin-bottom: 0;
}

.c-boilerplate--black {
	background-color: $color-black;
	color: $color-white;
	padding-top: $spacing-unit;
	padding-bottom: $spacing-unit;

	.c-boilerplate__item--border-left {
		border-left: 1px solid $color-white;
	}
}
