/* ------------------------------------*\
    #SERVICE-WIDGET
\*------------------------------------ */
$service-widget-padding: $spacing-unit;
$service-widget-width: 80%;
$service-widget-text-mb: $spacing-unit--tiny;
$service-widget-number-mb: $spacing-unit--tiny;
$service-widget-info-fs: 11px;

.c-service-widget {
	background-color: $color-gray-lighter;
	padding: $service-widget-padding;

	@include media-query(xl-up) {
		width: $service-widget-width;
	}
}

.c-service-widget__text {
	margin-bottom: $service-widget-text-mb;
	font-weight: $font-weight-medium;
	font-size: $secondary-font-size-xl;
}

.c-service-widget__number {
	font-size: $inuit-heading-size-5;
	margin-bottom: $service-widget-number-mb;
	font-weight: $font-weight-bold;
}

.c-service-widget__info {
	font-size: $service-widget-info-fs;
	margin-top: $spacing-unit--small;
}
