/* ------------------------------------*\
    #TEASER-CONSULTANT-ACCESSOIRES
\*------------------------------------ */
$teaser-consultant-accessoires-content-width: 40%;
$teaser-consultant-accessoires-content-width-m-down: 45%;
$teaser-consultant-accessoires-content-height: 100%;
$teaser-consultant-accessoires-content-padding-l-up: 72px;
$teaser-consultant-accessoires-content-padding-l-down: 0 9px;

.c-teaser-consultant-accessoires {
	@include media-query(m-up) {
		@include aspect-ratio(1360, 527);
	}

	@include media-query(m-down) {
		@include aspect-ratio(320, 146);
	}
}

.c-teaser-consultant-accessoires__content {
	float: right;
	width: $teaser-consultant-accessoires-content-width;
	color: $color-black;
	height: $teaser-consultant-accessoires-content-height;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: left;
	flex-direction: column;

	@include media-query(m-down) {
		width: $teaser-consultant-accessoires-content-width-m-down;
		align-items: center;
	}

	@include media-query(l-down) {
		padding: $teaser-consultant-accessoires-content-padding-l-down;
	}

	@include media-query(l-up) {
		padding-right: $teaser-consultant-accessoires-content-padding-l-up;
		padding-left: $teaser-consultant-accessoires-content-padding-l-up;
	}
}

.c-teaser-consultant-accessoires__headline {
	@include font-size($heading-secondary-font-size-gamma);

	@include media-query(m-down) {
		@include font-size($heading-secondary-font-size-delta);

		text-align: center;
		line-height: 1;
	}
}

.c-teaser-consultant-accessoires__text {
	font-weight: 400;
}

.c-teaser-consultant-accessoires__img {
	position: absolute;
	width: 100%;
	height: auto;
	left: 0;
}
