//https://github.com/parcelLab/parcelLab-js-plugin

.c-parcellab {
	.pl-action {
		color: #eee;

		.pl-show-more-text {
			line-height: 38px;
			color: $color-gray-dark;
			transition: all 0.2s linear;
		}

		&:hover {
			cursor: default;
			// cursor: pointer;
			color: $color-gray-darker !important;

			.pl-show-more-text {
				transition: all 0.2s linear;
				color: $color-gray-darker;
			}
		}
	}

	.pl-main {
		div.pl-box > div > div.pl-box-body {
			//because of the loader which is loading in background
			background: white;

			@include media-query(m-up) {
				min-width: 580px;
			}
		}
	}

	div.pl-row {
		margin: 0;
		background-image: url("https://cdn.parcellab.com/img/traceline.png");
		background-repeat: repeat-y;
		background-position: 33px -5px;

		&:first-of-type {
			background-image: url("https://cdn.parcellab.com/img/traceline-first.png");
		}

		&:last-of-type {
			background-position: 33px -103px;
			background-repeat: no-repeat;
			padding-top: 15px;
		}

		&:last-of-type:first-of-type {
			background: none;
		}
	}

	div.pl-alert {
		padding: 15px;
		border: 1px solid transparent;
		border-radius: 4px;

		&.pl-alert-success {
			background-color: rgba(var(--color-success), 0.1);
			border-color: var(--color-success);
		}

		&.pl-alert-danger {
			font-size: $inuit-heading-size-6;
			background-color: $color-error-bg;
			border-color: $color-error;
			text-align: left;
		}
	}

	div.pl-icon {
		float: left;
		margin: 0 15px 0 0;

		& > .fa-stack > * {
			color: $color-gray-dark;
		}
	}

	div.pl-text {
		display: table-cell;

		> small {
			font-size: $inuit-heading-size-milli;
		}
	}

	a.lang-select:hover {
		text-decoration: none;
	}

	a:hover,
	a:focus {
		text-decoration: none;
	}

	.pl-alert {
		color: #000;
	}

	div.pl-tab-container {
		border-bottom: 1px #ddd dashed;
		max-width: unset;
	}

	div.pl-tab {
		margin: 0;
		padding: 8px;
		opacity: 0.5;

		&:hover {
			// cursor: pointer;
			cursor: default;
			opacity: 0.8;
		}

		&.pl-active {
			background: whitesmoke;
			opacity: 1;
		}

		div + div {
			text-align: left;
		}

		div.pl-tab-content {
			width: 100%;
			position: relative;

			div.pl-tab-text {
				position: relative;
				overflow: hidden;
				text-overflow: ellipsis;
				padding-right: 40px;

				span.pl-tab-text-tno {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				span.pl-tab-text-status {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-size: 12px;
				}
			}

			div.pl-status {
				position: absolute;
				top: 0;
				right: 0;

				div.pl-icon {
					margin: 0;
					float: unset;
				}
			}
		}
	}

	div.pl-prediction {
		margin-bottom: 12px;

		.no-background {
			background-image: none !important;
			padding-top: 0;
			padding-bottom: 1px;
		}

		p {
			margin-top: 10px;
			margin-bottom: 14px;
		}
	}

	div.pl-more-infos {
		border: 1px #ddd dashed;
		padding: 10px 15px;
		border-radius: 5px;
	}
}
