/**
 * 8select add to Cart
 * nur auf der Shop The Look Seite
 */

#js-cart-popup_wrapper {
	.c-popup {
		padding: 0 !important;
		min-width: 350px;
	}

	.c-popup-close {
		top: 10px;
		right: 10px;
	}

	.c-cart-popup__headline {
		padding: 13px 40px 13px 18px;
		border-bottom: 2px solid var(--color-gray-40);
		min-height: 50px;

		h3 {
			line-height: 1;
			margin: 0;
		}
	}

	.c-cart-popup__content {
		padding: 18px;
	}

	.c-cart-popup__message-success {
		color: var(--color-success);
		margin-right: 20px;
		display: inline-block;
	}

	.c-cart-popup__icon-success {
		padding-right: 5px;
		font-size: 17px;
		vertical-align: text-bottom;
	}

	@include media-query(m-down) {
		.c-cart-popup__message-success {
			margin-right: 0;
			display: block;
		}

		.c-cart-popup__action-button {
			display: block;
			margin-top: 22px;
		}
	}
}
