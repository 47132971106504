/* ------------------------------------*\
    #CART
\*------------------------------------ */
$mobile-select-size: 30px;
$mobile-remove-btn-width: 46px;
$mobile-remove-btn-height: 38px;
$cart-upselling-wrapper-width: 684px;

//smallscreen
.c-cart-title--mobile {
	+ .c-icon--close {
		position: absolute;
		top: 0;
		right: $spacing-unit--small;
	}
}

.c-cart-option--count--mobile {
	> select {
		display: block;
		width: $mobile-select-size;
		height: $mobile-select-size;
		line-height: $mobile-select-size;
		padding-left: $spacing-unit--small;
		outline: 0;
		border: 1px solid $color-black;
		border-radius: 0;
		-webkit-appearance: none;
	}
}

.c-cart-quantity {
	@include media-query(m-down) {
		margin: -20px auto 0 auto;
	}
}

.c-cart-ip-marker {
	color: $color-white;
	background: $color-black;
	font-size: $secondary-font-size-xl;
	line-height: 1;
	padding: $inuit-base-spacing-unit--small;
	margin: $inuit-base-spacing-unit--small 0;
	text-align: center;

	@include media-query(m-up) {
		float: right;
		width: auto !important;
		white-space: nowrap;
	}

	@include media-query(l-down) {
		font-size: $secondary-font-size-l;
		padding: $inuit-base-spacing-unit--tiny;
	}
}

//desktop and mobile
.c-cart-article-remove-btn {
	font-weight: $font-weight-bold;
	cursor: pointer;

	//styles for remove button in offcanvas cart
	@include media-query(m-down) {
		position: absolute;
		width: $mobile-remove-btn-width;
		height: $mobile-remove-btn-height;
		text-align: center;
		top: -$spacing-unit--small;
		right: -$spacing-unit--small;
		margin-right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

//midscreen
.c-cart-article-remove-btn--midscreen {
	display: none;

	@include media-query(l-down) {
		position: absolute;
		display: block;
		bottom: $spacing-unit--large;
		right: 0;
		margin-bottom: 0;
		font-weight: $font-weight-bold;
		cursor: pointer;
	}
}

.c-cart-article-add-wishlist--midscreen {
	display: none;

	@include media-query(l-down) {
		position: absolute;
		display: block;
		bottom: $spacing-unit;
		right: 0;
		margin-bottom: 0;
		font-weight: $font-weight-bold;
		cursor: pointer;
	}
}

.c-cart-upselling-wrapper {
	@include media-query(m-up) {
		width: $cart-upselling-wrapper-width;
	}
}
