.accordion {
	&__border {
		border-bottom: 1px solid var(--color-black);
		border-top: 1px solid var(--color-black);
	}

	&__item {
		border-bottom: 1px solid var(--color-gray-20);

		&[open] .accordion__icon--plus {
			&::after {
				background: none;
			}
		}
	}

	&__item-first {
		border-top: 1px solid var(--color-gray-20);
	}

	&__button {
		position: relative;
		display: block;
		text-align: left;
		width: 100%;
		padding: $lib-space-m $lib-space-s;
		font-size: $u-fontsize;
		font-weight: 400;
		border: none;
		background: none;
		outline: none;
	}

	&__title {
		font-size: $u-fontsize;
		padding-right: $lib-space-l;
		cursor: pointer;
		margin-bottom: 0;
	}

	&__icon {
		position: absolute;
		top: $lib-space-m;
		right: 0;
		width: 22px;
		height: 22px;

		&--plus {
			&::before {
				position: absolute;
				content: "";
				top: 9px;
				left: 5px;
				width: 10px;
				height: 2px;
				background: currentColor;
			}

			&::after {
				position: absolute;
				content: "";
				top: 5px;
				left: 9px;
				width: 2px;
				height: 10px;
				background: currentColor;
			}
		}
	}

	&__content {
		font-size: $u-fontsize;
		overflow: hidden;

		p {
			margin-bottom: 0;
			padding: $lib-space-s;
			text-align: left;
		}
	}
}
