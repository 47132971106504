/* ------------------------------------*\
    #TEASER-CONSULTANT-DETAIL
\*------------------------------------ */
$teaser-consultant-detail-headline-mb: $spacing-unit;
$teaser-consultant-detail-headline-border-pos-b: -10px;
$teaser-consultant-detail-headline-border-pos-b-m-down: -6px;
$teaser-consultant-detail-headline-border-width: 50%;
$teaser-consultant-detail-headline-border-width-m: 60%;
$teaser-consultant-detail-subline-width: 70%;
$teaser-consultant-detail-content-width: 60%;
$teaser-consultant-detail-content-width-m: 65%;
$teaser-consultant-detail-content-width-m-down: 75%;
$teaser-consultant-detail-content-height: 100%;
$teaser-consultant-detail-content-padding: 0 10%;
$teaser-consultant-detail-text-font-size-m: 11px;

.c-teaser-consultant-detail {
	@include media-query(m-up) {
		@include aspect-ratio(902, 815);
	}

	@include media-query(m-down) {
		@include aspect-ratio(320, 146);
	}
}

.c-teaser-consultant-detail__content {
	float: right;
	width: $teaser-consultant-detail-content-width;
	padding: $teaser-consultant-detail-content-padding;
	color: $color-black;
	height: $teaser-consultant-detail-content-height;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;

	@include media-query(m-down) {
		width: $teaser-consultant-detail-content-width-m-down;
		height: 100%;
	}

	@include media-query(m) {
		width: $teaser-consultant-detail-content-width-m;
	}
}

.c-teaser-consultant-detail__headline {
	display: inline-block;
	position: relative;
	margin-bottom: $teaser-consultant-detail-headline-mb;
	font-weight: $font-weight-medium;
	color: $color-black;

	@include font-size($heading-secondary-font-size-alpha);

	@include media-query(l) {
		font-size: $heading-secondary-font-size-beta;
	}

	@include media-query(m) {
		font-size: $heading-secondary-font-size-gamma;
	}

	@include media-query(m-down) {
		@include font-size($heading-secondary-font-size-beta);

		margin-bottom: $teaser-consultant-detail-headline-mb/2;
	}

	&::after {
		content: "";
		position: absolute;
		left: 50%;
		height: 1px;
		bottom: $teaser-consultant-detail-headline-border-pos-b;
		border-bottom: 1px solid $color-black;
		margin-left: -($teaser-consultant-detail-headline-border-width/2);
		width: $teaser-consultant-detail-headline-border-width;

		@include media-query(m-down) {
			margin-left: -($teaser-consultant-detail-headline-border-width-m/2);
			width: $teaser-consultant-detail-headline-border-width-m;
			bottom: $teaser-consultant-detail-headline-border-pos-b-m-down;
		}
	}
}

.c-teaser-consultant-detail__subline {
	display: inline-block;
	font-weight: $font-weight-medium;
	font-size: $heading-secondary-font-size-delta;
	text-align: center;
	color: $color-black;
	width: $teaser-consultant-detail-subline-width;

	@include media-query(l) {
		font-size: $secondary-font-size-xl;
	}

	@include media-query(m) {
		font-size: $heading-secondary-font-size-epsilon;
	}

	@include media-query(m-down) {
		width: 100%;
	}
}

.c-teaser-consultant-detail__text {
	display: inline-block;
	font-weight: 400;
	width: 100%;

	@include media-query(m) {
		font-size: $teaser-consultant-detail-text-font-size-m;
	}
}

.c-teaser-consultant-detail__img {
	position: absolute;
	width: 100%;
	height: auto;
	left: 0;
}
