/* ------------------------------------*\
    #RETURN-DOWNLOAD
\*------------------------------------ */

$return-download-button-image-size: 30px;
$return-download-steps-border: 1px solid $color-black;
$return-download-steps-number-size: 40px;
$return-download-steps-number-border: 2px solid $color-black;
$return-download-giro-code-size: 200px;
$return-download-service-border: 1px solid $color-black;
$return-download-service-info-fs: 11px;
$return-download-giro-code-tooltip-img-height: 50px;
$return-download-giro-code-tooltip-img-width: 100px;

.c-return-download {
	@include media-query(m-down) {
		padding: $spacing-unit;
	}
}

.c-return-download-button__image {
	width: $return-download-button-image-size;
	height: $return-download-button-image-size;
}

.c-return-download-steps {
	@include media-query(m-up) {
		border-right: $return-download-steps-border;
	}
}

.c-return-download-steps--borderless {
	@include media-query(m-up) {
		border-right: none;
	}
}

.c-return-download-steps__number {
	border-radius: 50%;
	width: $return-download-steps-number-size;
	height: $return-download-steps-number-size;
	background: $color-white;
	border: $return-download-steps-number-border;
	color: $color-black;
	text-align: center;
	font-weight: $font-weight-bold;
	font-size: $inuit-heading-size-4;
	padding: $spacing-unit--tiny;
}

.c-return-download-steps__text {
	font-size: $inuit-heading-size-4;
	font-weight: $font-weight-bold;
}

.c-return-download-steps__message {
	font-weight: $headline-font-weight;

	@include font-size($headline-font-size);

	margin-top: $spacing-unit--large;
}

.c-return-download-giro-code {
	width: $return-download-giro-code-size;
	height: $return-download-giro-code-size;
	margin: auto;
}

.c-return-download-giro-code-tooltip {
	display: inline-block;
}

.c-return-download-giro-code-tooltip__img {
	height: $return-download-giro-code-tooltip-img-height;
	width: $return-download-giro-code-tooltip-img-width;
}

.c-return-download-service {
	border-top: $return-download-service-border;
}

.c-return-download-service__item {
	font-size: $inuit-heading-size-4;
	font-weight: $font-weight-bold;
	margin-top: $spacing-unit;
}

.c-return-download-service__info {
	font-size: $return-download-service-info-fs;
}
