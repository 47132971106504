/* ------------------------------------*\
    #SIZE-ERROR
\*------------------------------------ */
//size error
.c-size-error {
	position: absolute;
	display: inline-block;
	margin-top: -1px; //magic number
	margin-left: $spacing-unit--small;
}
