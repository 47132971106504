/* ------------------------------------*\
    #BADGE
\*------------------------------------ */

$badge-padding: $inuit-base-spacing-unit--small;
$badge-size: 44px;
$badge-size-m-down: 36px;
$badge-mobile-size: 34px;
$badge-font-size: $secondary-font-size-s;
$badge-border-width: 1px;
$badge-border-font-size: 36px;

/**
 * These rules extend `.o-badge {}` in objects/_badge.scss.
 */

.c-badge--circle {
	display: inline-block;
	padding: 0 6px;
	font-weight: 500;

	@include font-size(15px, 50px);

	border-radius: 50%;

	&.c-badge__border {
		background-color: $color-white;

		&::before {
			content: $icon-font--badge-circle;

			@include icon-font(48px);

			position: absolute;
			left: 0;
			font-weight: 100;
		}
	}
}

.c-badge {
	position: absolute;
	width: $badge-size;
	height: $badge-size;
	line-height: $badge-size;
	display: inline-block;
	font-size: $badge-font-size;
	font-weight: 700;
	text-align: center;

	@include media-query(m-down) {
		font-size: $inuit-base-font-size;
		width: $badge-size-m-down;
		height: $badge-size-m-down;
		line-height: $badge-size-m-down;
	}

	&.c-badge__border {
		&::after {
			content: $icon-font--badge-circle-filled;

			@include icon-font(44px);

			position: absolute;
			left: 0;
			font-weight: 100;
			z-index: -1;

			@include media-query(m-down) {
				font-size: $badge-border-font-size;
			}
		}
	}

	&.c-badge--new {
		color: $color-white;

		&::after {
			color: $color-black;
		}
	}

	&.c-badge--sale {
		color: $color-brand-primary;

		&::after {
			color: $color-gray-light;
		}
	}
}

.c-badge__border--concise {
	border-color: $color-brand-primary;
	color: $color-brand-primary;
}

.c-badge__border--base {
	border-color: $color-gray-dark;
	color: $color-gray-dark;
}
