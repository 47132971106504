//https://github.com/parcelLab/parcelLab-js-plugin

.c-parcellab {
	.hidden {
		display: none;
	}

	.hide-on-mobile {
		@include media-query(m-down) {
			display: none;
		}
	}

	.hide-on-desktop {
		@include media-query(m-up) {
			display: none;
		}
	}

	// GRID
	.pl-container {
		position: relative;
		width: 100%;
		max-width: 960px;
		margin: 0 auto;
		padding: 0 0;
		box-sizing: border-box;

		&::after {
			content: "";
			display: table;
			clear: both;
		}
	}

	.pl-col-row,
	.pl-cf {
		&::after {
			content: "";
			display: table;
			clear: both;
		}
	}

	.pl-col {
		width: 100%;
		float: left;
		box-sizing: border-box;
	}

	.pl-box {
		.pl-box-heading {
			display: none;
		}

		.pl-box-footer {
			padding: 25px 0;
			text-align: right;

			& > a {
				@extend .o-arrow-link;
				@extend .o-arrow-link--bold;

				text-transform: none;

				i.fa {
					display: none;
				}
			}
		}
	}

	.pl-loading {
		padding: 25px 0;
		text-align: center;
		font-size: 2em;
	}

	.pl-img-responsive {
		display: block;
		max-width: 100%;
		height: auto;
	}

	// todo: braucht es das fologende????
	#pl-shop-info-container .pl-box-body > a,
	#pl-mobile-shop-info-container .pl-box-body > a {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		display: inline-block;
	}

	.pl-opening-hours-box {
		border: 0;
		border-top: 1px #ddd dashed;
		border-top-left-radius: 0;
		border-top-right-radius: 0;

		.pl-closes-in {
			font-size: 12px;
		}

		.pl-box-heading {
			font-size: 1em;
			padding: 10px 20px;
			border: 0;
		}

		.pl-box-body {
			padding: 10px 0;

			&:not(.pl-open) {
				@include media-query(m-down) {
					display: none;
				}
			}
		}

		.opening-hours-entry {
			&,
			& > div {
				font-size: 11px;

				@include media-query(m-down) {
					font-size: 14px;
				}
			}

			padding: 0 20px;

			.pl-week-day-col {
				float: left;
				width: 50%;
			}

			.pl-hours-col {
				float: left;
				width: 50%;
			}

			&.highlighted-entry {
				background-color: #eee;
			}
		}
	}

	.pl-input {
		border-radius: 3px;
		background: white;
		border: 1px solid #b2b2b2 !important;
		font-size: 1rem;
		color: #333;
		background-color: #fff;
		border-color: #ccc;
		width: 100%;
	}

	.pl-btn {
		padding: 5px 10px;
		line-height: 1.5;
		border-radius: 3px;
		background: white;
		border: 1px solid #b2b2b2 !important;
		font-size: 1rem;
		color: #333;
		background-color: #fff;
		border-color: #ccc;
		// cursor: pointer;
	}

	.pl-btn-block {
		width: 100%;
	}

	button.pl-cta-button {
		display: inline-block;
		height: 35px;
		padding: 0 30px;
		color: #fff;
		text-align: center;
		letter-spacing: 0.1rem;
		text-decoration: none;
		white-space: nowrap;
		background-color: #bbb;
		border-radius: 4px;
		border: 1px solid #bbb;
		cursor: pointer;
		box-sizing: border-box;
	}

	input[type="email"].pl-form-input,
	input[type="number"].pl-form-input,
	input[type="search"].pl-form-input,
	input[type="text"].pl-form-input,
	input[type="tel"].pl-form-input,
	input[type="url"].pl-form-input,
	input[type="password"].pl-form-input,
	textarea.pl-form-input,
	select.pl-form-input {
		height: 35px;
		padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
		background-color: #fff;
		border: 1px solid #d1d1d1;
		border-radius: 4px;
		box-shadow: none;
		box-sizing: border-box;
	}

	/* Removes awkward default styles on some inputs for iOS */
	input[type="email"].pl-form-input,
	input[type="number"].pl-form-input,
	input[type="search"].pl-form-input,
	input[type="text"].pl-form-input,
	input[type="tel"].pl-form-input,
	input[type="url"].pl-form-input,
	input[type="password"].pl-form-input,
	textarea.pl-form-input {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	.pl-reroute-link {
		margin-bottom: 15px;
		display: block;
		text-decoration: none;
		border: 1px solid #d1d1d1;
		color: #333;
		text-align: center;
		padding: 10px;
		border-radius: 3px;
		font-size: 16px;
		transition: 0.1s all linear;

		&:hover {
			transform: scale(1.015);
			transition: 0.1s all linear;
		}

		@include media-query(m-down) {
			font-size: 15px;
		}
	}

	.pl-reroute-link-short {
		display: block;
		text-decoration: none;
		border: 1px solid #d1d1d1;
		color: #333;
		text-align: center;
		padding: 10px;
		border-radius: 3px;
		transition: 0.1s all linear;
		margin-bottom: 15px;

		small {
			opacity: 0.7;
			font-size: 0.8em;
		}

		&:hover {
			transform: scale(1.015);
			transition: 0.1s all linear;
		}
	}

	.pl-reduced-margin {
		margin-bottom: 15px;
	}
}

#parcellab-track-and-trace.parcellab-styles {
	.pl-button {
		border-radius: 9999px;
		.pl-img-responsive {
			// the icons are generated in black color.
			// But that is not working on black background so here we hide the icon
			display: none;
		}
	}
}
