/* ------------------------------------*\
    #COUNTRY-LIST
\*------------------------------------ */

.flag-de {
	background-image: url("/_ui/responsive/common/images/country-flags/germany.png");
}

.flag-at {
	background-image: url("/_ui/responsive/common/images/country-flags/austria.png");
}

.flag-ch {
	background-image: url("/_ui/responsive/common/images/country-flags/switzerland.png");
}
