/* ------------------------------------*\
    #COUNTRY RESTRICTION LAYER
\*------------------------------------ */

$popup-country-layer-width: 620px;

.c-popup-country {
	padding: $inuit-base-spacing-unit--large;
	max-width: $popup-country-layer-width;

	@include media-query(m-down) {
		width: 100%;
		padding: $inuit-base-spacing-unit;
	}
}

.c-popup-country--close {
	position: absolute;
	top: -$inuit-base-spacing-unit;
	right: -$inuit-base-spacing-unit;
	padding: $inuit-base-spacing-unit--small;
	font-size: $inuit-base-font-size;
	border-radius: 50%;
	color: $color-white;
	background-color: $color-black;
	border: none;

	@include media-query(m-down) {
		top: $inuit-base-spacing-unit--small;
		right: $inuit-base-spacing-unit--small;
	}
}

.c-popup-country--hover:hover .o-layout__item {
	opacity: 0.25;
}

.c-popup-country--hover .o-layout__item:hover {
	opacity: 1;
}
