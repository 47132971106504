/* ------------------------------------*\
    #TEASER CONSULTANT SLIDER
\*------------------------------------ */
$consultant-teaser-slider-overlay-button-padding: 50px;

.c-consultant-teaser-slider {
	display: none;

	&.slick-initialized {
		display: block;
	}
}

.c-consultant-teaser-slider__slides {
	position: relative;
	text-align: center;
}

.c-consultant-teaser-slider__img {
	width: 100%;
}

.c-consultant-teaser-slider__headline {
	position: absolute;
	top: 2%;
	width: 100%;
	text-align: center;
	font-weight: $font-weight-medium;
	color: $color-black;
	font-size: $heading-secondary-font-size-gamma;
}

.c-consultant-teaser-slider__overlay {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.c-consultant-teaser-slider__overlay-text {
	@include media-query(m-up) {
		display: none;
	}
}

.c-consultant-teaser-slider__overlay-button {
	padding-right: $consultant-teaser-slider-overlay-button-padding;
	padding-left: $consultant-teaser-slider-overlay-button-padding;

	@include media-query(m-down) {
		width: 100%;
	}
}
