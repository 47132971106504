/* ------------------------------------*\
    #POPUP-ARTICLE
\*------------------------------------ */

$popup-artikel-width: 1500px;

.c-popup-cartvalidate-layer {
	@include media-query(m-down) {
		height: 100%;
	}
}

.c-popup-cartvalidate {
	background-color: $color-white;
	padding: $spacing-unit;
	max-width: $popup-artikel-width;

	.c-popup-cartvalidate-close {
		position: absolute;
		top: -$spacing-unit;
		right: -$spacing-unit;
		padding: $spacing-unit--small;
		font-size: $inuit-base-font-size;
		border-radius: 50%;
		color: $color-white;
		background-color: $color-black;
		border: none;

		@include media-query(m-down) {
			top: $spacing-unit--small;
			right: $spacing-unit--small;
		}
	}

	@include media-query(m-down) {
		height: 100%;
	}
}
