//password security
$strength-height: 15px;
$strength-bottom: -90px;
$strength-bottom-small: -85px;
$strength-bottom-tiny: -90px;

.c-strength {
	display: inline-block;
	width: 60%;

	@include media-query(m-down) {
		width: 100%;
	}
}

.c-strength_meter {
	background: $color-gray-dark;
}

.c-strength_meter div {
	height: $strength-height;
	width: 100%;
	text-align: center;
	color: transparent;
	font-weight: bold;
	line-height: $strength-height;

	&.veryweak {
		background-color: $color-brand-primary;
	}

	&.weak {
		background-color: #ffb78c;
	}

	&.medium {
		background-color: #ffec8b;
	}

	&.strong {
		background-color: $color-ok;
	}
}
