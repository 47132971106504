/* ------------------------------------*\
    #SIZE-TABLE
\*------------------------------------ */

$size-table-font-size: 12px;
$size-table__head-column-padding: 5px;
$size-table-body-row-height: 30px;
$size-table-width-m-down: 800px;

//--------------------------------
// Size Chart
.c-popup {
	@include media-query(m) {
		overflow: initial !important;

		.popupContent {
			width: 100%;
		}
	}

	@include media-query(m-down) {
		overflow: initial !important;
		-webkit-overflow-scrolling: touch; /* because of scrolling on iOS, enables momentum-based scrolling */

		& .c-size-chart-image {
			height: 100%;
			max-width: none;
		}

		.popupContent {
			min-height: 100%;
			width: auto;
			overflow-x: auto;
			-webkit-overflow-scrolling: touch;
		}
	}

	&.scroll-wrapper {
		padding: $spacing-unit !important;
		overflow: initial !important;
	}

	.scroll-content {
		position: initial !important;
	}
}

.c-size-table {
	font-size: $size-table-font-size;
}

.c-size-table--fixed {
	@include media-query(m-down) {
		width: $size-table-width-m-down;
	}
}

.c-size-table-image {
	height: auto;
	max-width: 100%;
}

.c-size-table__head {
	color: $color-white;
	background-color: $color-black;
}

.c-size-table__head-column {
	font-weight: 400;
	padding: $size-table__head-column-padding;
}

.c-size-table__body-row {
	color: $color-black;
	height: $size-table-body-row-height;

	&:nth-child(odd) {
		background-color: $color-gray-lighter;
	}

	&:nth-child(even) {
		background-color: $color-gray-light;
	}
}
