//https://github.com/parcelLab/parcelLab-js-plugin

.c-parcellab {
	.pl-box-aside {
		display: none !important; // keep rating box hidden
	}

	.rating {
		unicode-bidi: bidi-override;
		direction: rtl;
		font-size: 2em;
		color: #ddd;
	}

	.rating > span:hover,
	.rating > span:hover ~ span {
		//  content: "\2605";
		color: #58b957;
		// cursor: pointer;
	}

	h3 {
		margin: 0 0 25px;
	}

	.rating-body {
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		color: #aaa;

		& > div.pl-courier-vote {
			font-size: 1.8em;
			flex-grow: 1;
			text-align: center;
			border-radius: 5px;
			transition: all 0.2s linear;
			padding: 5px 0;

			&.up {
				border: 1px #eee solid;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}

			&.down {
				border: 1px #eee solid;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}

			&:hover {
				transition: all 0.2s linear;

				&.up {
					color: #58b957;
					border-color: #58b957;
				}

				&.down {
					color: #d0524e;
					border-color: #d0524e;
				}
			}
		}
	}

	.pl-box-maps {
		padding: 0 !important;
		height: 140px;
		position: relative;
		transition: all 0.25s linear;

		&:hover {
			.pl-box-action-info {
				visibility: visible;
				opacity: 1;
				transition: all 0.25s linear;
			}

			.pl-address-info {
				visibility: hidden;
				opacity: 0;
				position: absolute;
				bottom: 0;
				// transition: all .15s linear;
			}
		}

		.pl-address-info {
			width: 100%;
			position: absolute;
			bottom: 0;
			color: #fff;
			display: flex;
			padding: 0 10px 10px;

			& > div {
				text-align: center;
				font-weight: normal;
				font-size: 14px;
				padding: 5px 10px;
				background: rgba(0, 0, 0, 0.55);
				border-radius: 5px;
				width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin: auto auto 8px auto;
			}
		}

		.pl-box-action-info {
			padding: 20px;
			background-color: rgba(50, 50, 50, 0.7);
			border-radius: 5px;
			height: 100%;
			width: 100%;
			visibility: none;
			opacity: 0;
			transition: all 0.25s linear;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			color: #eee !important;
		}
	}

	.pl-box-cal-heading {
		padding: 8px;
		// background-color: rgb(29, 141, 189);
		// background-color: rgb(71, 71, 71);
		background-color: rgb(209, 74, 74);
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		border-color: #eee !important;

		h3 {
			color: #eee;
			margin: 0;
		}
	}

	.pl-box-cal-body {
		margin: 25px 0;
		text-align: center;
		font-size: 20px;
		position: relative;

		.pl-cal-day {
			font-weight: bold;
			font-size: 50px;
		}

		.pl-cal-week-day {
			font-weight: 200;
			font-size: 15px;
		}

		.pl-cal-month {
			font-weight: 200;
			font-size: 15px;
		}
	}

	.pl-box-cal-btn {
		font-size: 18px;
		color: #ddd;
		position: absolute;
		top: -25px;
		right: 5px;
	}

	.pl-box-time {
		padding: 10px 0;

		.pl-time-data {
			font-size: 20px;
		}

		.pl-time-caption {
			color: #aaa;
			font-size: 13px;
		}

		hr {
			margin: 5px 0;
		}
	}

	.pl-prediction-caption {
		color: #aaa;
		font-size: 13px;
	}

	#pl-prediction-map {
		height: 250px;
		width: 100%;
	}

	.pl-prediction-map-markerLabels {
		background-color: rgba(255, 255, 255, 0.8);
		color: #333;
		padding: 6px;
		border: none;
		opacity: 1 !important;
		white-space: nowrap;
		margin-left: -30px !important;
		font-weight: 400;
	}

	#pl-pickup-location-map {
		height: 250px;
		width: 100%;
	}
}
