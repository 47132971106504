/* ------------------------------------*\
    #RETURN-SUMMARY
\*------------------------------------ */

.c-return-summary-voucher {
	border: 1px solid $color-brand-primary;
	padding: $spacing-unit;
	position: relative;
}

.c-return-summary-articel__price {
	font-size: $inuit-heading-size-5;
	font-weight: $font-weight-bold;
}

.c-return-summary-articel__price-mobile {
	font-size: $inuit-heading-size-6;
	font-weight: $font-weight-bold;
}

//---------------------------
//------- Price Summary on Summary Page
//---------------------------

.c-return-summary-price {
	background: $color-white;
	padding-top: $spacing-unit;
	padding-bottom: $spacing-unit;

	@include media-query(m-down) {
		padding-left: $spacing-unit--small;
		padding-right: $spacing-unit--small;
		padding-top: $spacing-unit--small;
		padding-bottom: $spacing-unit--small;
	}
}

.c-return-summary-price.is-fixed {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	box-shadow: 0 -5px 14px -4px rgba(0, 0, 0, 0.45);
	z-index: 499;

	@include media-query(m-up) {
		padding-left: $spacing-unit;
		padding-right: $spacing-unit;
	}
}

.c-return-summary-price__button {
	@include media-query(m-down) {
		font-size: 12px;
	}
}

//---------------------------
//------- Price Summary
//---------------------------

.c-return__price-summary {
	border-top: 1px solid $color-gray-dark;
	padding-top: $spacing-unit;
}

.c-return__price-summary__row {
	margin-bottom: 0;
	text-align: right;
	padding-bottom: $spacing-unit--small;

	> span {
		white-space: nowrap;
	}

	@include media-query(m-down) {
		padding-bottom: $spacing-unit--tiny;
	}
}

.c-return__price-summary__totalprice {
	margin-bottom: $spacing-unit--tiny;
	font-size: $inuit-heading-size-4;
	padding-bottom: $spacing-unit--tiny;

	> span {
		font-weight: $font-weight-bold;
		white-space: nowrap;
	}

	@include media-query(m-down) {
		font-weight: $font-weight-bold;
		font-size: $inuit-heading-size-6;
		border: 0;
		padding-bottom: 0;
	}
}
